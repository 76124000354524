div.hourly-helper {
    border: solid 1px;
    border-radius: 5px;
    margin: 3%;
    width: 94%;
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


select.income-input {
    width: 60%;
    border-radius: 20px;
    padding: 1% 2% 1% 2%;
    height: 100%;
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none;
     margin-top: 3%;
  
  /* Add arrow icon */
  /* Source: https://material.io/tools/icons/?icon=keyboard_arrow_down&style=baseline */
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" xml:space="preserve"><path d="M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z"/><path fill="none" d="M0,0h24v24H0V0z"/></svg>');
  background-position: center right;
  background-repeat: no-repeat;
}


.hourly-input {
    width: 30%; 
    margin-left: 3%;
}

label.income {
    width: 40%;
}

div.signup_container {
    display: flex;
    width: 100%; 
    height: 100%;
}

div.heading {
    width: 100%;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    text-align: center;
    margin:0;
    padding:0; 
}

div.heading > p {
    margin-top: 2%;
    margin-bottom: 0
}

.est-annual-income {
    text-align: center;
    width: 100%;
    font-size: 1.2em;
}

.wage_type_selection_container {
    width: 90%;
    display: flex; 
    margin-top: 5%;
    font-size: 1em;
}

input[type=radio] {
    border: 0px;
    width: 20%;
    height: 1.3em;
}