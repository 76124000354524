button.remove {
    float: right;
    width: 100%;
    padding: 0%; 
    height: 100%;
    margin-right: 3%;
    margin-left: auto;
    background-color: transparent;
    border: none;
    color:rgb(181, 181, 181);
    font-size: 1em;
}

p.account_name {
    /* margin-left: 5%; */
    margin-top: 3%;
    margin-bottom: 0%;
    padding: 0%;
    width: 80%;
    font-size: 1.1em;
}

p.amount {
    /* margin-left: auto; */
    margin-top: 0;
    width: 80%;
    font-size: .9em;
}

div.connected_account {
    display: flex; 
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 3%;
    border-bottom: 1px solid rgb(222, 221, 221);
    width: 90%;

}

div.last {
    display: flex; 
    align-items: center;
    /* border-bottom: none */
}

div.first {
    display: flex; 
    align-items: center;
    padding-bottom: 3%;
    border-top: 1px solid rgb(222, 221, 221);
}