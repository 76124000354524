/* Place the navbar at the bottom of the page, and make it stick */
.navbar {
  background-color: rgb(237, 237, 237);
  overflow: hidden;
  position: absolute;
  bottom: 0;
  /* max-width: inherit; */
      max-width: 415px;

  display: flex; 
    align-items: stretch; /* Default */
    justify-content: space-between;
    border-radius: 25px;
    border-top-right-radius: 25px;
    width: 97%;
    margin-left: 1.5%;
    margin-bottom: 1.5%;
    /* margin-right: auto; */
}

/* Style the links inside the navigation bar */
.navbar li {
  display: inline-block;
  color: black;
  text-align: center;
  padding: 9px 20px;
  text-decoration: none;
  font-size: 1em;
  flex: 1 1 auto; /* Default */
}

/* Change the color of links on hover */
/* .navbar li:hover {
  background-color: rgb(165, 162, 162);
  color: black;
} */

/* Add a color to the active/current link */
.navbar li.active {
  background-color: #7682a2;
  color: white;
  border-radius: 25px
}