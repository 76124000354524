div.nav_circle {
    width: 20%;
    /* float: right;  */
    height: 100%; 
    display: flex;
    margin-top: .25em;
}

.right_side {
    float: right;
    margin-left: .55em;

}

.left_side {
    float: left;
    margin-right: .55em;
}

span.navigation {
  text-align: center;
  /* margin: 6em 0 0 0; */
  padding: 0;
  width: 3em;
  height: 3em;
  border: 2px solid #333;
  border-radius: 50%;
  display: inline-block;
  background-color: white;
}

a:hover .left, a:hover .top, a:hover .bottom, a:hover .right{
  /* border: 0.5em solid #e74c3c; */
}

a:hover .left:after, a:hover .top:after, a:hover .bottom:after, a:hover .right:after {
  /* border-top: 0.5em solid #e74c3c;
  border-right: 0.5em solid #e74c3c; */
}

.left {
}

.left:after {
  content: '';
	display: inline-block;
  margin-top: .8em;
  margin-left: 0.45em;
  width: 1.05em;
  height: 1.05em;
  border-top: 3px solid #333;
  border-right: 3px solid #333;
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.top {
  display: inline-block;
  width: 4em;
  height: 4em;
  border: 0.5em solid #333;
  border-radius: 50%;
  margin-right: 0.75em;
}

.top:after {
  content: '';
	display: inline-block;
  margin-top: 1.4em;
  width: 1.4em;
  height: 1.4em;
  border-top: 0.5em solid #333;
  border-right: 0.5em solid #333;
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.bottom {
  display: inline-block;
  width: 4em;
  height: 4em;
  border: 0.5em solid #333;
  border-radius: 50%;
  margin-left: 0.75em;
}

.bottom:after {
  content: '';
	display: inline-block;
  margin-top: 0.6em;
  width: 1.4em;
  height: 1.4em;
  border-top: 0.5em solid #333;
  border-right: 0.5em solid #333;
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.right {
}

.right:after {
  content: '';
	display: inline-block;
  margin-top: .8em;
  margin-left: -0.45em;
  width: 1.05em;
  height: 1.05em;
  border-top: 3px solid #333;
  border-right: 3px solid #333;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}