.todo_item {
    /* background-color: #e8e8e8; */
    background-color: #e0e0e0;
    /* border: 1px solid; */
    border-radius: 10px;
    width: 85%;
    margin-top: 2.3%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    display: flex;
    padding: 2%;
    /* box-shadow: .5px .5px */
}

.todo-item {
    width: 100%; 
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-left: 3%;
    
}

input.todo {
    transform: scale(1.8);
    margin-left: 3%; 
    /* padding increase the clickable area */
    padding: 8%; 
    background: #e8e8e8;
}