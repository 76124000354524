@import url(//fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap);

div#root {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: rgb(232, 234, 241);
    height: 100%;
    margin: 0;
    padding: 0; 
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Roboto Light'; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI Light', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

html {
    height: 100%;
    width: 100%;
}





/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
