input.form-control {
    width: 98%;
    border-radius: 30px;
    border: 1px solid rgb(238, 237, 237);
    padding: 5%;
    font-size: 1em
}

label.signup-label {
    width: 100%;
    margin: 0;
}

div.input-field {
    width: 100%;
    flex-shrink: 0;
    /* margin-top: 4%; */
    display: flex;
    justify-content: right;
    align-items: stretch;
    min-height: 3vh;
    max-height: 5vh;
}

div.signup-label {
    width: 100%;
    flex-shrink: 0;
    margin-top: 4%;
    margin-bottom: 1%;
    display: flex;
    justify-content: left;
    align-items: center;
}

div.signup-container {
    width: 100%;
    display: flex; 
    flex-wrap: wrap;
    margin-top: 3%;
}

div.centered-button {
    /* position: absolute; */
    /* this also controls the button in the todo help  */
    width: 100%;
    margin-top: 10%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* height: 100%; */
}  


.signup-alternative {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    /* flex: auto; */
}

