.little-section {
    width: 20%;
    border: 1px solid; 
    /* background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='2' y='13' fill='gray' font-size='12' font-family='arial'>$</text></svg>"); */

}

.big-section {
    width: 60%;
}

.emergency-fund-edit {
    margin: 0%; 
    flex-wrap: wrap;
}

