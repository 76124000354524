div.modal{
  z-index: 1;
  background-color: rgb(240, 240, 240);
  width: 100%;
  height: 70%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  position: relative;
  /* animation: transform ease-in .05s; */
}
/* .modal button {
  position: absolute;
  top: 7px;
  right: 7px;
  padding: 8px 11px;
  background: crimson;
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
} */
@keyframes transform {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}