button.account_selection {
    width: 100%;
    margin-left: auto; 
    margin-right: auto;
    background: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 4%;
    border-radius: 20px;
    border: none;
    border: 1.5px solid #eeeeee;
    font-size: 1em;
    box-shadow: .3px .3px .3px .3px #f7f6f6;

}

button.selected {
    border: 1.5px solid #8facc2;
    background-color: #e7f2f9; 
    width: 100%;

}

div.account_selection {
    width: 90%;
    margin-left: auto; 
    margin-right: auto;
    margin-top: 4%;
}