.settings-card {
    border: 1px solid; 
    width: 90%;
    display: flex;
    margin-right: 5%;
    margin-left: 5%;
    border-radius: 5px;
    margin-top: 2%;
    flex-wrap: wrap;
}

.tall {
    padding: 12px 0 12px; 

}

.settings-left {
    width: 80%;
    padding-left: 3%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
}

.settings-right {
    width: 10%;
    display: flex;
    justify-content: right;
    align-items: center;
}

div.title {
    font-size: 1.2em;
    display: flex; 
    align-items: center;
    width: 70%;
    flex-wrap: wrap;
    margin: 0%;
    padding: 0%;
    /* padding: 6%; */
}

div.icon {
    display: flex;
    width: 10%;
    align-items: center;
    padding: 3%;
}

.subheading {
    display:flex; 
    width: 100%;
    font-size: .7em;
    align-items: center;
    margin: 0%;
    padding: 0%;
}