.headerbar {
    height: 18vh;
    background-color: #6B76AB;
    /* background-color: #d0d9ad; */
    /* background-color: white; */
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

div.header-container {
    width: 100%;
    text-align: center;
}

.header_text {
    color: white;
    font-weight: 300;
    font-size: 3.3vh;
    width: 94%;
    margin: 3%;
}

h2.header_text {
    color: white;
    font-weight: 300;
    font-size: 1.2em;
}


      /* Small Devices, Tablets
  @media only screen and (min-width : 768px) {
    width: 75%;
    background-color: #67aa78;
  } */

  /* Medium Devices, Desktops */
  @media screen and (max-width : 500px) {
    .headerbar {
        /* background-color: #3e66b1; */
        /* background-color: #456CB6 */
    }
  }
