h1 {
    text-align: center;
    font-weight: 400;
    font-size: 1.6em
}

div.checklist {
    margin-top: 10%;
    display:flex; 
    justify-content: center;
}

div.rectangle {
    border: 1px solid;
    border-radius: 10px;
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;
}

button.corner-circle {
    border-radius: 50%;
    border: .5px solid;
    height: 7vw; 
    width: 7vw; 
    max-height: 28px;
    max-width: 28px
}

button.update-goal {
    margin-bottom: 3%;
}