/* ovverriding the react plaid link inline styles with !important */
.CustomPlaidLink {
    width: 100% !important;
    /* bottom: 0 !important; */
    /* margin-bottom: 10%; */
    /* position: absolute; */
    font-size: 1.2em;
    margin-bottom: 0% !important;
    background: #6B76AB !important; 
    border-radius: 20px !important;
    border: none;
    /* border: none; */
    font-size: 1.2em !important;
    color: rgb(246, 246, 246) !important;
}

