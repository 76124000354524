.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


main {
  height: 100%; 
  width: 100%;
  margin: 0;
  padding: 0;
    background-color: #f9f9f9; 
    max-width: 415px;
  /* background-color: white; */
  overflow: auto;
}

.body_text {
    text-align: left;
    font-size: 1.2em;
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 5%;
    font-weight: 300;
}

a {
    text-decoration: none;
    color: black;
    width: 100%;
    text-align: center;
    /* width: 100%; */
}

img {
    width: 92%;
    /* margin-left: 4%; */
}

p.nodiv {
    padding: 0%;
    margin-bottom: 0%;
}

p.center {
    text-align: center;
}

div.full-container {
    /* height: 100vh; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    width: 100%;
}

div.container {
    width: 88%; 
    margin-top: 3%; 
    margin-left: 6%;
    margin-right: 6%;
}

div.same-row {
    width: 100%;
    display: flex; 
    align-items: center;
    margin-top: 3%;
}

input:not([type='checkbox']) {
    -webkit-appearance:caret;
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
}



p.normal-text {
    font-size: 1em; 
    margin:0; 
    padding:0;
}

div.with-navbar {
  height: 100vh;  
}

#portal .modal-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 0;
  background: rgba(63, 63, 63, 0.7);
}

ol {
    padding:0;
    margin: 4%;
}

p.header {
    font-size: 1.8em;
    font-weight: 600;
}