button.logout {
    font-size: 1em;
    padding: 3%;
    width: 50%;    /* color: red; */

}

div.logout {
    display: flex; 
    height: 100%;
}

.settings-container {
    margin-top: 2%;
    width: 100%;
}

