div.continue_button {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 17%;
    max-width: 415px;
    /* margin-top: 15%; */
}

button.continue {
    /* width: 66%; */
    width: 80%;
    background: rgb(233, 233, 233);
    /* background: rgb(204, 212, 231); */
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 3%;
    border-radius: 20px;
    border: none;
    /* border: none; */
    font-size: 1.1em;
    color: rgb(155, 155, 155);
    pointer-events: none;
    max-width: 415px;

}

button.clickable {
    background: #6B76AB;
    color: rgb(246, 246, 246);
    pointer-events: all;

}