  .progress{
    position: relative;
    text-align: center;
    width: 100%;
    justify-content: center;
    display: flex;
    
  }
  .barOverflow{ /* Wraps the rotating .bar */
    position: relative;
    overflow: hidden;
    width: 22vh; height: 11vh;
    margin: 5%;
    /* margin-bottom: -15px; */

  }
  .bar{
    width: 22vh; height: 22vh; /* full circle! */
    border-radius: 50%;
    box-sizing: border-box;
    border: 15px solid #eee;     /* half gray, */
    border-bottom-color: #67aa78;  /* half azure */
    border-right-color: #67aa78;
    rotate: var(--rotation);
  }

  div.text-container {
    width: 96%;
    margin-left: 3%;
    margin-right: 1%;
    display: flex;
    flex-wrap: wrap;
  }

  p.progress-label {
    /* margin-top: 7vh;  */
    margin-left: 1.5vw;
    position: absolute;
    bottom:0;
    font-size: 1.6em;
  }