.progress_bar_container {
    margin-top: 8%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
.progress_bar {
    width: 80%;
    height: 8px;
    border: 2px solid; 
    color: rgb(192, 192, 192);
    margin-left: 10%;
    border-radius: 10px;
    border: 1px solid;
}

.inside {
    color: #67aa78;
    background-color: #67aa78;
    border: none;
    margin-left: 0%;
    animation: progressBar 1.5s ease-in;
    /* animation-delay: .5s; */
    /* animation-fill-mode:both;  */
}


@keyframes progressBar {
  0% { width: 0; }
  100% { width: var(--to-width) }
}