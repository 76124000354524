.help-section {
    width: 90%;
    margin-left: 5%;
}

.help {
    overflow: scroll;

}

button.back {
    margin-top: 2vh;
}