.connected_accounts_container {
    width: 80%; 
    border: solid .5px; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

