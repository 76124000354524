div.feedback-form {
    width: 100%;
    display: flex;
}

div.feedback-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

textarea.feedback-input {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5%;
    border-radius: 8px;
} 

button.feedback {
    height: 8%;
    padding: 2%;
    font-size: 1em;
    margin-top: 8%;
}