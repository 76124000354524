div.big-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* background-color: rgb(255, 201, 134); */
    background-color: rgb(249, 248, 247);
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.transition {
    font-size: 1.6em;
    font-weight: 300;
    animation: fadeIn 2s linear
}

img.transition {
    height: 275px;
    width: 275px; 
    padding: 8%;

}

div.fade-in-delay {
    animation: fadeIn 1s linear both;
    animation-delay: 2s;
}