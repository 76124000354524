div.company-name {
    width: 60%;
}

div.login-signup {
    width: 40%;
    display: flex;
    align-items: center; /* Default */
    justify-content: space-between;
    justify-content: stretch;
    font-weight: 500;
}

div.top-bar {
    width: 100%; 
    display: flex;
    /* background-color: aqua; */
}

div.main-text {
    display: flex;
    flex-wrap: wrap;
}

div.screenshot {
    margin-top: 12%;
    /* background-color: rgb(250, 249, 249); */
    border-radius: 15px;
    /* border: 1px solid;
    height: 100px */
}

div.blue {
    /* background-color: rgb(203, 211, 246); */
    height: 100%;
    background-color: rgb(210, 216, 243);

}


